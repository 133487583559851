
.main_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
}

.bottomText{
  color: white;
  margin-top: 10px !important;
  font-size: 17px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    height: 18px
  }
  


.emailLogin {
  border: "none";
}

.first_box {
  /* background-color: #f5f5f5; */
  height: 530px;
  display: flex;
  position: relative;
}

.form {
  /* grid-column: 2;
    grid-row: 2; */
  width: auto;
  height: auto;
  margin: auto;
  /* padding: 20px; */
  background-color: transparent;
  /* opacity: 85%; */
  border-radius: 10px;
  /* box-shadow: 0 32px 64px rgba(0, 0, 0, 0.2); */
}

.form h2 {
  margin-bottom: 5px;
  text-align: center;
  /* text-shadow: 0 4px 16px #fff; */
  font-size: 30px;
  font-weight: 100;
}


.longin_form_container {
  width: 480px !important;
  margin-top: 70px;
}

.login_button {
  width: calc(100% - 10px) !important;

}



.inputField {
  background: white;
  border-radius: 5px;
  height: 50px;
  width: 480px;
  border: none;
}

.inputField .MuiOutlinedInput-input {
  padding: 15px 14px 15px 0px !important;
}


/* .form input {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #c8bba1;
  opacity: 50%;
} */

.login_button {
  width: 460px;
  color: black !important;
  background: #c8bba1 !important;
  margin: 40px 0px 0px 0px !important;
  border: 2px solid black;
  font-weight: 600;
  /* padding: 10px; */
  height: 40px;
  font-weight: bold !important;
  font-size: 20px !important;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  /* background: #f5f5f5; */
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */
}


.forget .button2 {
  margin-left: 5px;
}

.forget {
  display: flex;
  justify-content: space-evenly;
  padding-top: 10px;
}

.forget button {
  /* padding: 10px; */
  border-radius: 2px;
}

.successful_msg {
  color: green;
  display: flex;
  justify-content: center;
}

.errMsg {
  color: red;
  display: flex;
  justify-content: center;
}

.main_part .MuiContainer-root {
  display: flex;
  justify-content: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.first_box {
  margin-top: 5%;
  display: flex;
  justify-content: center;
}

.css-1ss2ei0-MuiContainer-root {
  max-width: 100% !important;
}

.video-container {
  width: 640px;
  border-radius: 4px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 8px 20px rgba(black, 0.4);
}

/* .video-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        } */
/* .video {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        } */

/* .play-button-wrapper {
        width: 90px;
        position: absolute;
        top: 270px;
        right: 400px;
      } */
#circle-play-b {
  cursor: pointer;
  pointer-events: auto;
}

svg {
  width: 100px;
  height: 100px;
  fill: #fff;
  stroke: #fff;
  cursor: pointer;
  background-color: rgba(black, 0.2);
  border-radius: 50%;
  opacity: 0.9;
}



/* video::-webkit-media-controls{
  display: none;
} */
.play-button-wrapper {
  display: block;
  margin: auto;
  width: 90px;
}

.play-gif {
  display: block;
  position: absolute;
  top: 235px;

}

.video_box {}

.login_form_container, .main_part {
  min-width: 470px;
  max-width: 470px;
}
.forgotPassText{
  text-underline-position: under;
  font-size: 16px !important;
}

.MuiSvgIcon-fontSizeInherit{
  stroke: #008304 !important;
}


@media screen and (max-width:1300px) {
  .video_box {
    display: none;
  }


}

@media screen and (max-width:650px) {
  .video_box {
    display: none;
  }

  .login_form_container, .main_part {
    min-width: 400px;
    max-width: 400px;
  }


}

@media screen and (max-width:550px) {
  .video_box {
    display: none;
  }

  .login_form_container, .main_part {
    min-width: 350px;
    max-width: 350px;
  }


}

@media screen and (max-width:450px) {
  .video_box {
    display: none;
  }

  .login_form_container, .main_part {
    min-width: 310px;
    max-width: 310px;
  }
  .bottomText{
  color: white;
  font-size: 13px !important;
  margin: 10px 20px 0px auto !important
}
.rememberMeText, .forgotPassText{
  font-size: 14px !important;
}



}


.congratulations {
  font-size: 15px;
  margin-bottom: 30px;
  transform: translate(30%, 175%)!important;
  z-index: 10000 !important;
  position: absolute;
  width: 70% !important;
}