.header_login {
  background: #c8bba1;
  height: 90px !important;
  box-shadow: 0 0 2px rgb(0 0 0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  /* width: calc(100% - 30px); */
  width: calc(100% - 5px);
}

.header_login .left_section {
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  align-items: center;
}

.header_login .left_section .first_image_logo,
.header_login .left_section .second_image_logo {
  width: 155px;
  height: 48px;
}

.header_login .right_section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 1%;
  font-size: 15px;
  font-weight: 600;
  margin-right: 20px;
}

.header_login .right_section .user_logo {
  width: 41px;
  height: 41px;
  border-radius: 50%;
}
.left_section{

}

.premiumallianceLogo{
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 30px;
}
header {
  background-color: #fff !important;
  height: 60px;
  justify-content: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

  .MuiContainer-root {
    max-width: 1880px !important;
    padding: 0px 40px;

    @media (max-width: 650px) {
      padding: 0px 20px;
    }
  }

  @media (max-width: 650px) {
    padding: 0px;
  }
}
.hamburgerMenu{
  display: none !important;
}

.sideBarBox{
  display: flex !important;
  flex-direction: column !important;
  background-color: #c8bba1;
  
}

.hamburgerMenu .css-4t3x6l-MuiPaper-root-MuiDrawer-paper{
  background-color: #c8bba1 !important;
}

.sideBarItem{
  
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .header_login{
    justify-content: "center";

  }
  .right_section {
    /* display: none !important; */
  }
.hamburgerMenu{
  display: block !important;
}
.left_section{
  width: 95% !important;
}

  
}


@media (max-width: 650px) {
  .header_login .left_section .first_image_logo,
  .header_login .left_section .second_image_logo {
    width: 130px;
    height: 33px;
    /* margin-right: 5px; */
  }
  .header_login{
    padding-left: 0px !;
  }
  .premiumallianceLogo{
    margin-left: 5px !important;
  }
}

