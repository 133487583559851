
.middle_header {
    font-size: medium;
    justify-content: space-between;
    padding-bottom: 1px;
    height: 57px;
    align-items: end;
    display: flex;
    /* margin-bottom: 18px; */
    font-size: 21px;
    font-weight: 600;
    color: #1c1c1c;
}
