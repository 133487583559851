table, th, td {
  border: 1px solid black;
  border-collapse: collapse;

}
td{
  padding:6px 10px
}

.dataTitle{
  width: 30% !important;
  font-weight: 600;
}
.mainGridContainer{
  width: 500px !important;
  height: 330px;

  @media screen and (max-width: 600px) {
    height: 360px;
    width: 350px !important;
  }

  @media screen and (max-width: 500px) {
    height: 400px;
    width: 100% !important;
  }

  @media screen and (max-width: 400px) {
    height: 410px;
    width: 270px !important;
  }
}