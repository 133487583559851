.img1_inflation{
    width: 1039px;
    padding:  10px 10px 0px 10px;
}
.welcome_div{
    width: 1020px;
    padding: 10px;
    background-color: #c8bba1;
    height: 130px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
    font-weight: 600;
    margin-top: -4px;
}

.para1{
    width: 1020px;
    padding: 10px;
    margin-left: 10px;
}

.para2_header,.para2_table, .for_li, .para3 ,.para4{
    /* width: 1020px; */
    padding: 10px;
    margin-left: 10px;
}
.two_part{
    display: flex;
    flex-direction: row;
}
.part1 , .part2{
    width: 50%;
}
.part2{
    padding-left: 140px;
}
ul{
    padding: 7px;
}.dashboard_page_inflation{
    height: auto;
}
.imp{
    padding: 10px;
    margin-left: 10px;
}
.para5 ,.para6 , .para7 ,.para8, .para9 , .para10{
    padding: 5px;
}
.middle_inflation{
    background-color: white !important ;
    opacity: 1 !important;
}
.calc_div{
    width: 1020px;
    padding: 10px;
    background-color: rgba(0,0,0,.03);
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 10px;
    height: 130px;
    margin-left: 5px;
}
.comp7{
display: flex;
margin: 10px;
padding: 10px;
}
.component1,.component3,.component5{
    width: 15%;
    border: 2px solid rgba(0,0,0,.125);
    border-bottom: 1px solid rgba(0,0,0,.125);
    text-decoration: none;
    text-transform: none;
    border-radius: 5px
}

/* .component2 ,.component4, .component6{
    padding: 10px;
    margin-left: 10px;
    margin-top: -10px;
} */
.calc_header ,.calc_header1{
    display: flex;
    justify-content: center;
}
.component6 {
    /* margin-left: 20px; */
    width: 150px;
    color :green;
    font-weight: 600;
}
.component7 {
    margin-left: 80px !important;
}
.canvas_chart{
    /* margin: 10px; */
    padding: 10px;
    margin: 10px;
    margin-left: -10px;
}
.canvasjs-chart-credit{
    top: 400px !important;
}
li{
    padding: 5px;
}
.middle_section .two_part  .part2 .para2_content{
    padding: 10px !important;
}
.para2_header{
    padding-top: 10px;
}
.inputadornment{
    align-items: center;
    display: flex;

}
.component7{
    height: 40px;
}
.box_of_input{
    border: 2px solid rgba(0,0,0,.125);
    display: flex;
    align-items: center;
    width: 25px;
    justify-content: center;
}
.box_of_textbox{
    border-top: 1px solid rgba(0,0,0,.125);
    border-right: 1px solid rgba(0,0,0,.125);
    border-bottom: 1px solid rgba(0,0,0,.125);
    padding-left: 5px;
    background-color: white;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}
.box_of_textbox_of_5{
    border-left: 1px solid rgba(0,0,0,.125);
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}
.box_of_input{
    border: 1px solid rgba(0,0,0,.125);
    background-color: #e9ecef;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
   
}
.component7{
    background: green !important;
    margin-left: 5px !important;
}
.component4_box , .component6_box{
    justify-content: center;
    align-items: center;
}
.component2_box{
    width: 210px;
    justify-content: flex-end;
    align-items: center;
}
.component1_box ,.component2_box ,.component3_box ,.component4_box ,.component5_box,.component6_box,.component7_box {
    display: flex;
    flex-direction: row;
    height: 35px;
    /* padding: 5px; */
    margin: 5px;
    width: 350px;
    /* justify-content: center; */
}
.css-a88p61-MuiInputBase-root-MuiInput-root{
    width: 100%;
    height: 38px;
}
.component4_box  {
    width: 183px;
    justify-content: flex-end;
}
.box_of_textbox .MuiInput-root::before{
    border-bottom: 0px solid !important;
}
.box_of_textbox .MuiInput-root::after{
    border-bottom: 0px solid white !important;
}
.box_of_textbox.css-a88p61-MuiInputBase-root-MuiInput-root:after{
    border-bottom: 0px solid white !important;
    text-decoration: none;
    text-transform: none;
}

@media screen and (min-width:0px) and (max-width:420px) {
    .welcome_div, .img1_inflation{
        width: 200px;
    }
    
    .para2_header,.para2_table, .for_li, .para3 ,.para4{
        width: 94%; 
    }
    .calc_div{
        width: 90%;
       
    }
    .link_inflation {
        padding-left: 55% !important;
        width: 0px;
    }
    .welcome_div{
        text-align: center;
    }
    .two_part {
        display: flex;
        flex-direction: column;
    }
    .part2 {
         padding-left: 15px; 
    }
    .comp7{
        display: flex;
    flex-direction: column;
    justify-content: flex-start;
    }
    .calc_div{
        height: 480px;
    }
    .dashboard_page .left_section {
        width: 85%;
    }
    .dashboard_page .middle_section {
        width: 85%;
    }
    .dashboard_page .right_section {
        min-width: 91%;
    }
    .welcome_div, .img1_inflation {
        width: 91%;
    }
    .welcome_div {
        
       font-weight: 400;
      
    }
    .img1_inflation {
        width: 97%;
    }
    .para1 {
        width: 94%;
        padding: 10px;
        margin-left: 10px;
    }
    .part1, .part2 {
        width: 94% !important;
    }
    .para2_header, .para2_table, .for_li, .para4 {
        width: 100%;
    }
    /* .para3, .para4 {
        width: 92% !important;
    } */
    .calc_header{
        margin: 10px;
    }
    .component1_box, .component2_box, .component3_box, .component4_box, .component5_box {
        display: flex;
        flex-direction: row;
        height: 35px;
        padding: 5px;
        margin: 5px;
        /* justify-content: flex-start; */
    }
    .component6_box {
        display: flex;
        flex-direction: row;
        height: 35px;
        padding: 5px;
        margin: 5px;
        justify-content: center;
    }
    .component1_box ,.component2_box ,.component3_box ,.component4_box ,.component5_box,.component6_box,.component7_box {
        display: flex;
        flex-direction: row;
         justify-content: flex-start; 
         width: 250px;
    }
    .component2_box  ,.component4_box ,.component6_box {
     display: flex;
     align-items: flex-end;
     margin-bottom: -10px;
    }
    .para2_header, .para2_table, .for_li, .para3, .para4 {
         margin-left:0px; 
    }
}
@media screen and (min-width:421px) and (max-width:820px) {
    .img1_inflation {
        width: 95%;
        padding: 10px 10px 0px 10px;
    }
    .welcome_div {
        width: 92%;   
    }
    .link {
        /* padding-left: 62%;
        width: 0px;  */
        margin-left: auto;
    margin-right: 0px;
    padding-left: 0px ;
    }
    .para1 {
        width: 95%;
    }
    .calc_div {
        width: 92%;
        height: 420px;
    }
    .two_part {
        display: flex;
        flex-direction: column;
    }
    .two_part  .part1, .two_part .part2 {
        width: 99%;
    }
    .two_part  .part2 {
         padding-left: 20px; 
    }
    .comp7{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .component1_box ,.component2_box ,.component3_box ,.component4_box ,.component5_box,.component6_box,.component7_box {
        display: flex;
        flex-direction: row;
         justify-content: flex-start; 
         width: 250px;
    }
    .component2_box  ,.component4_box ,.component6_box {
     display: flex;
     align-items: flex-end;
     /* margin-bottom: -5px; */
    }
    .para2_header, .para2_table, .for_li, .para3, .para4 {
         margin-left:0px; 
    }
   
    
}
@media screen and (min-width:821px) and (max-width:1521px) {
     .img1_inflation ,.welcome_div,.para1,.calc_div{
        width: 95%;
        }
    .link{
        padding-left: inherit;
    margin-left: auto;
    width: 0px;
    }
    .part2{
        padding-left: 0px;
    }
    
}
@media screen and (min-width:1522px) and (max-width:2000px) {
    .img1_inflation ,.welcome_div,.para1,.calc_div{
       width: 95% !important;
       }
   .link{
       padding-left: inherit;
   margin-left: auto;
   width: 0px;
   }
   .part2{
       padding-left: 0px;
   }
   
}
@media screen and (min-width:825px) and (max-width:1325px) {
    .calc_div{
        height: max-content;
    }
    .comp7{
        flex-direction: column;
    }
    .component1_box, .component2_box, .component3_box, .component4_box, .component5_box, .component6_box, .component7_box {
 
        justify-content: center;
    }
    .component1_box, .component2_box, .component3_box, .component4_box, .component5_box, .component6_box, .component7_box {
        justify-content: center;
        width: 100% !important;
    }
    .component2_box, .component4_box{
        align-items: flex-end;
    }
    .component6_box{
        justify-content: center;
    }
    .calc_div {
        width: 96% !important;
    }
    
}
@media screen and (max-width: 1300px) {
    .main_header{
        width: 100%;
        margin-bottom: 20px;
    }
}