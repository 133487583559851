.email_box {
  width: 40%;
  height: max-content;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  padding: 2rem;
  text-align: center;
}
.email_box_main{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 55px;
}
.verify_mail{
  /* padding: 10px;
  padding-top: 50px;
  padding-left: 40px; */
  font-weight: bold;
  font-size: xx-large;
  color: green;
  padding-bottom: 0.8rem;
}
.content{
  /* padding: 30px;
  padding-left: 40px;
  padding-top: 30px; */
  /* letter-spacing: 1px; */
  line-height: 22px;
  margin-top: 1rem;
}
.email_box .MuiDivider-fullWidth{
  width: 100%;
  /* margin-left: 4%; */
}

.verifyemail_logout_btn {
  background-color: #c8bba1;
  color: black;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 2rem;
  margin-left: 1rem;
  font-weight: 600;
}

.verifyemail_logout_btn:hover {
  /* background-color: #e74c3c; */
}

.verifyemail_resend-email-btn {
  background-color: #c8bba1;
  color: #000;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.verifyemail_resend-email-btn:hover {
  /* background-color: #a69d87; */
}