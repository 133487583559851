.dialog_content {
    width: calc(100% - 20px) !important;
    min-height: 500px !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    padding: 10px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-family: var(--font-family-sans-serif);
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 1132px !important;
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
}

.main_head {
    margin-top: 30px;
    font-size: 20px;
    font-weight: 900;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-family: var(--font-family-sans-serif);

    color: #1c2735;

}

.iframe_dialog {
    height: 450px !important;
}

.first,
.third {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    flex-direction: column;
}

.first,
.second,
.third,
.fourth {
    width: calc(50% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    margin: 10px;
    padding: 10px;
}

.first a span,
.second a span,
.third a span,
.fourth a span {
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    margin: 10px;
    color: #1c2735
}

.first a,
.second a,
.third a,
.fourth a {
    text-align: left;
    width: 100%;
}

.first a div,
.second a div,
.third a div,
.fourth a div {
    margin: 10px;
    width: 100%;
    text-align: justify;
    padding-left: 10px;
    font-size: 16px;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    /* padding-right: 25px; */
}

@media screen and (min-width:0px) and (max-width:420px) {

    .first,
    .second,
    .third,
    .fourth {
        width: 85%;
    }

    .dialog_content {
        display: flex;
        /* flex-direction: column    ; */

    }

    .main_head {
        margin-top: 250px;
        margin-left: inherit;
    }
}

@media screen and (min-width:421px) and (max-width:500px) {
    .main_head {
        margin-top: 110%;
        margin-left: inherit;
    }
}

/* @media screen and (min-width:461) and (max-width:500px){
    .main_head {
        margin-top: 180%;
        margin-left: inherit;
    }
} */

@media screen and (min-width:501px) and (max-width:580px) {
    .main_head {
        margin-top: 40%;
        margin-left: inherit;
    }
}