*,
::after,
::before {
  box-sizing: unset !important;
}


.na-main-container {
  margin-top: 70px;
  margin-bottom: 20px;
  justify-content: center;
}


.MuiDataGrid-columnHeader--selected {
  outline: none !important;
}

.na-datagrid-item .MuiDataGrid-columnHeader:hover {
  outline: none !important;
}

.root-grid-item {
  margin-left: 40px;
}

.na-datagrid-item .MuiButton-startIcon {
  margin-right: 0px !important;
  /* font-size: 9px !important; */
  /* width: 5px !important; */
}



.tp_box_secondary_container {
  align-items: end !important;
}

.na-main-item1 {
  margin: 0 auto !important;
}

.MuiDataGrid-columnHeadersInner {
  flex: 1 !important;
  color: black;
}

/* .css-rc5ywe-MuiSwitch-root .MuiSwitch-switchBase {
  color: #c8bba1;
} */

.na-agnet-main-heading {
  color: black;
  font-size: 20px;
  font-weight: 600;
}

.na-datagrid-item .MuiDataGrid-cell:hover,
.na-datagrid-item .MuiDataGrid-cell:hover {
  color: black !important;
}

.na-datagrid-item .MuiDataGrid-row:hover,
.na-datagrid-item .MuiDataGrid-row:hover {
  background: #e4dfd6 !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  width: auto;
  margin: 0px
}


.na-blue-white-main-item {
  margin: 10px auto !important;
}

.na-table-blue-container {
  justify-content: start;
  background-color: #f4f1ec;
  padding: 25px 25px;
}

.na-table-white-container1 {
  background-color: white;
  padding: 20px 0px 0px 0px;
  margin: 0px 0px 0px;
}

.na-table-white-container2 {
  background-color: white;
  padding: 10px 20px;
}

.na-main-container .MuiGrid-root {
  width: unset;
}

.popUpContainer {
  width: 500px;
}


.na-registration-btn-item {
  margin-right: 5px !important;
  text-transform: capitalize !important;
}

.na-agentlist-btn-item {
  margin-right: 5px !important;
}

#na-agent-reg-btn-effect {
  color: black;
  background-color: #c8bba1;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 20%);
  border: none !important
}

#tp-agent-reg-btn-effect-default {
  color: black;
  background-color: white;
  border: 1px solid black;
}


.na-registration-btn-class {
  text-transform: capitalize !important;
  font-size: 15px;
  font-weight: 600;
}

.na-agentlist-btn-class {
  text-transform: capitalize !important;
  font-size: 15px;
  font-weight: 600;
}

.tp_box_secondary_container {
  width: 104% !important;
}

.vl {
  height: 67px;
  border-left: 3px solid #869aa8;
  margin-left: 1px;
}

.user-list-toggle-button-container {
  justify-content: flex-start;
  margin: 10px 0px 0px 0px;
}

.na-datagrid-item {
  /* background-color: white !important; */
}

.na-datagrid {
  border: none !important;
}

.na-datagrid .MuiDataGrid-columnHeaders {
  background-color: #c8bba1 !important;
  color: #fff;
}

.na-datagrid .MuiIconButton-sizeSmall {
  color: #fff !important;
}

.na-datagrid .MuiDataGrid-sortIcon {
  color: #fff !important;
}

.na-datagrid .MuiDataGrid-columnSeparator--sideRight {
  visibility: hidden;
}

.agent-status-row .MuiFormControlLabel-root {
  margin-left: 18% !important;

}

.agent-plus-box {
  text-align: end;
  /* margin: 10px 0px; */
  padding: 10px 0px;
}

.agent-plus-icon {
  color: #c8bba1;
  height: 60px !important;
  width: 60px !important;
  cursor: pointer;
}


.agent-label-warning {
  background-color: orange;
  padding: 2px 5px;
  border: 1px solid orange;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 600;
  color: black;
  font-weight: 600;
}

.agent-label-resendemail {
  background-color: #1976d2;
  padding: 2px 5px;
  border: 1px solid #1976d2;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 600;
  color: #fff;
  font-weight: 600;
  margin-left: 0.3rem !important;
  cursor: pointer;
}

.na-datagrid .MuiDataGrid-toolbarContainer {
  display: flex;
  margin-left: auto;
  float: right;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}



.tp_total_and_tables_container_item {
  width: 55% !important;
  height: 100% !important;
  background-color: #b4a891;
  /* margin: auto !important; */
}

.tp_box_total_item {
  color: white;
  display: flex;
  text-align: center;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  /* transform: rotate(270deg) scale(1) translateX(-20px) translateY(85px); */
  transform: rotate(270deg) scale(1) translateX(-15px) translateY(45px);
}

.tp_box_total_label {
  color: black;
}

.tp_box_main_item {
  margin: 0 10px !important;
}

.tp_box_main_container {
  margin: 15px 0px;
  justify-content: space-between;
  background-color: white;
}

.tp_box_agent_admin_container {
  justify-content: center;
  text-align: center;
}

.tp_box_admin_item {
  background-color: #cec2aa;
  padding: 5px 0px;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
}

.tp_box_agent_admin_label {
  font-size: 15px;
  font-weight: 600;
}

.tp_box_status_item {
  color: white;
  padding: 2px 0px;

}

.tp_status_active {
  background-color: #cec2aa;
  color: black;
  text-align: center;
  padding: 5px 0px;
  /* border-top-left-radius: 5px !important; */
  margin: 0px 10px;

}

.tp_box_status_label {
  font-size: 15px;
  font-weight: 600;
}

.tp_status_pending {
  background-color: #cec2aa;
  color: black;
  text-align: center;
  padding: 5px 0px;

}

.tp_status_inactive {
  background-color: #cec2aa;
  color: black;
  text-align: center;
  padding: 5px 0px;
  border-top-right-radius: 5px !important;


}

.tp_status_active_count {
  text-align: center;
  background-color: #E0E9F7;
  padding: 5px 0px;
  /* border-bottom-left-radius: 5px !important; */

}

.tp_status_pending_count {
  text-align: center;
  background-color: #E0E9F7;
  padding: 5px 0px;
}

.tp_status_inactive_count {
  text-align: center;
  background-color: #E0E9F7;
  padding: 5px 0px;
  border-bottom-right-radius: 5px !important;

}

.tp_box_agent_count_item {
  background-color: #E0E9F7;
  padding: 5px 0px;
  border-bottom-left-radius: 5px !important;

}

.tp_box_admin_count_item {
  background-color: #E0E9F7;
  padding: 5px 0px;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;


}


.agent-pending-table-btn1 {
  background-color: green !important;
  margin: 0px 5px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;

}

.agent-pending-table-btn2 {
  background-color: red !important;
  margin: 0px 5px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;


}

.optionIcon {
  color: #000000 !important;
  cursor: pointer;
}

.optionsClass:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(59 120 206 / 18%) !important;
  border-top: 1px solid #c8bba1;
  border-bottom: 1px solid #c8bba1;
}

.optionsClass {
  color: black !important;
  font-weight: 500;
  /* max-height:10px */
}

.agent-edit-dlt-menu .MuiPaper-root {

  /* box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 1%), 0px 5px 5px 0px rgb(0 0 0 / 1%), 0px 5px 5px 0px rgb(0 0 0 / 1%); */
  box-shadow: 0px 5px 1px 0px rgb(0 0 0 / 0%), 0px 5px 2px 0px rgb(0 0 0 / 0%), 0px 3px 6px 0px rgb(0 0 0 / 1%);
}


.agent-edit-dlt-menu ul {
  border-right: 3px solid #c8bba15c;
  border-bottom: 3px solid #c8bba15c;

}

.agent-admin-badge {
  border: 1px solid green;
  padding: 3px 6px;
  border-radius: 3px;
  color: black;
  font-size: 9px;
  font-weight: 700;
  margin-left: 8px;
}

/* .css-1knaqv7-MuiButtonBase-root-MuiButton-root{
  color: #000000;
} */

.na-main-container .MuiGrid-root {
  width: 99%;
}

.na-table-blue-container .MuiDataGrid-toolbarContainer {}

.na-table-white-container2 .MuiDataGrid-toolbarContainer .MuiButton-root {
  color: #000000;
}

.na-table-white-container1 {
  width: 100% !important;
}

.na-table-white-container1 .MuiCollapse-wrapper {
  margin-left: 15px;
}


.accordion {
  width: 500px;
  margin: 10px 0px 0px 0rem;
  border-radius: 5px;
}

.accordion .MuiAccordionDetails-root {
  padding: 15px 0px 7px 0px !important;
}

.na-table-white-container1 .MuiAccordionDetails-root {
  padding: 8px 0px 0px 0px !important;
}

.na-table-white-container1 .MuiButtonBase-root-MuiAccordionSummary-root {
  min-height: 0px !important;
  max-height: 45px !important;
}

.na-datagrid-item .MuiDataGrid-columnHeader:focus {
  outline: none !important;
}

/* datagrid columns style  */
.user_full_name_column_style {
  max-width: 400px;
  min-width: 250px;
}

.user_email_column_style {
  max-width: 500px;
  min-width: 300px;
}

.created_at_column_style {
  max-width: 300px;
  min-width: 200px;
}

.status_column_style {
  max-width: 200px;
  min-width: 100px;
}

.lastColumn_style {
  max-width: 80px;
  min-width: 80px;
}

.na-table-white-container2 .MuiDataGrid-columnHeader--moving {
  background: #c8bba1 !important;
}

.RegistrationRequestColumns_user_full_name,
.RegistrationRequestColumns_user_email {}


.na-table-white-container1 .MuiAccordionSummary-root {
  min-height: 50px !important;
}

.na-table-white-container1 .MuiAccordionSummary-root .Mui-expanded {
  margin: 10px 0px !important;
}


.na-table-white-container1 .Mui-expanded:first-of-type {
  /* margin-bottom: 0px; */
}


.dropdown-menuitem {
  padding: 0px 8px !important
}

@media only screen and (max-width:1800px) and (min-width:1550px) {
  .user_full_name_column_style {
    max-width: 350px !important;
    min-width: 350px !important;
  }

  .status_column_style {
    max-width: 150px !important;
    min-width: 150px !important;
  }

}

@media only screen and (max-width:1550px) and (min-width:1500px) {
  .user_full_name_column_style {
    max-width: 330px !important;
    min-width: 330px !important;
  }

  .status_column_style {
    max-width: 150px !important;
    min-width: 150px !important;
  }

}

/* @media only screen and (max-width:1650px) {
  .RegistrationRequestColumns_user_full_name{
    max-width: 280px !important;
    min-width: 200px !important;
  }
}
@media only screen and (max-width:1600px) {
  .RegistrationRequestColumns_user_email{
    max-width: 325px !important;
    min-width: 250px !important;
  }
} */

@media only screen and (max-width:1500px) and (min-width:1400px) {
  .user_full_name_column_style {
    max-width: 300px !important;
    min-width: 300px !important;
  }

  .status_column_style {
    max-width: 150px !important;
    min-width: 150px !important;
  }
}



@media only screen and (max-width:1650px) and (min-width:1400px) {
  .RegistrationRequestColumns_user_full_name {
    max-width: 220px !important;
    min-width: 220px !important;
  }

  /* .RegistrationRequestColumns_user_email{
    max-width: 150px !important;
    min-width: 150px !important;
  } */
}

@media only screen and (max-width:1550px) and (min-width:1500px) {
  .RegistrationRequestColumns_user_full_name {
    max-width: 210px !important;
    min-width: 210px !important;
  }

  .RegistrationRequestColumns_user_email {
    max-width: 360px !important;
    min-width: 360px !important;
  }
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
  .RegistrationRequestColumns_user_full_name {
    max-width: 200px !important;
    /* min-width: 200px !important; */
  }

  .RegistrationRequestColumns_user_email {
    max-width: 330px !important;
    /* min-width: 330px !important; */
  }
}


/* To remove total title in tab and mobile screen */
@media only screen and (max-width:980px) {
  .tp_total_and_tables_container {
    display: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .popUpContainer {
    width: 320px !important;
  }

  .na-table-white-container2 {
    margin-right: 5px;
  }


}


@media only screen and (max-width: 500px) {
  .popUpContainer {
    width: 270px !important;
  }

  .na-table-white-container1 {
    padding-top: 0px;
  }

  .tp_box_main_container {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .na-datagrid {
    font-size: 13px !important;
  }

  .na-table-white-container2 {
    margin-right: 5px;
    padding-top: 20px !important;
  }
}

@media only screen and (max-width: 400px) {
  .popUpContainer {
    width: 250px !important;
  }
}



@media only screen and (max-width:1440px) and (min-width:1282px) {

  .tp_box_agent_admin_label {
    font-size: 14px;
  }

  .tp_box_status_label {
    font-size: 14px;
  }

  .tp_box_agent_label {
    font-size: 14px;
  }
}

@media only screen and (max-width:1440px) and (min-width:1200px) {
  .tp_total_and_tables_container_item {
    width: 45% !important;
  }

  .tp_box_total_item {
    transform: rotate(270deg) scale(1) translateX(-15px) translateY(45px);
  }

}


@media only screen and (max-width:1280px) and (min-width:1024px) {
  .tp_box_status_label {
    font-size: 13px;
  }

  .tp_box_agent_admin_label {
    font-size: 13px;
  }

  .tp_box_agent_label {
    font-size: 13px;
  }
}


@media only screen and (max-width:1199px) and (min-width:1000px) {
  .rp-dropdown-main-item {
    margin: 0px 25px 0px 0px !important;
  }

  .na-main-container .MuiGrid-root {
    width: 98%;
  }

  .tp_box_secondary_container {
    width: 112% !important;
    align-items: unset !important;
  }
}

@media only screen and (max-width:1000px) and (min-width:800px) {

  .tp_box_secondary_container {
    width: 128% !important;
    align-items: unset !important;
  }

  .na-main-container .MuiGrid-root {
    width: 97%;
  }
}

@media only screen and (max-width:800px) and (min-width:700px) {

  .tp_box_secondary_container {
    width: 128% !important;
    align-items: unset !important;
  }

  .na-main-container .MuiGrid-root {
    width: 97%;
  }
}

@media only screen and (max-width:700px) and (min-width:500px) {

  .tp_box_secondary_container {
    width: 128% !important;
    align-items: unset !important;
  }

  .na-main-container .MuiGrid-root {
    width: 97%;
  }
}


@media only screen and (max-width:1199px) {
  .tp_box_secondary_container {
    flex-direction: column-reverse !important;
    /* display: flex; */
  }
}

@media only screen and (max-width:1199px) and (min-width:1024px) {
  .tp_total_and_tables_container_item {
    width: 50% !important;
  }

  .tp_box_total_item {
    transform: rotate(270deg) scale(1) translateX(-17px) translateY(45px);
  }

}

@media only screen and (max-width:1000px) {
  .tp_box_secondary_container {
    width: 118% !important;
    /* align-items: unset !important; */
  }

}



@media only screen and (max-width:1024px) and (min-width:951px) {
  .tp_total_and_tables_container_item {
    width: 50% !important;
  }

  .tp_box_total_item {
    transform: rotate(270deg) scale(1.5) translateX(-10px) translateY(46px);
  }

  .tp_box_secondary_container {
    align-items: unset !important;
  }

}

@media only screen and (max-width:1024px) and (min-width:1019px) {
  .tp_box_total_item {
    transform: rotate(270deg) scale(1) translateX(-14px) translateY(46px);
  }

}

@media only screen and (max-width:1018px) {
  .tp_box_status_label {
    font-size: 13px;
  }

  .tp_box_agent_admin_label {
    font-size: 13px;
  }

  .tp_box_agent_label {
    font-size: 13px;
  }

  .tp_box_total_label {
    font-size: 14px;
  }

  .tp_box_total_profile_label {
    font-size: 14px;
  }
}

@media only screen and (max-width:950px) and (min-width:901px) {
  .tp_total_and_tables_container_item {
    width: 60% !important;
  }

  .tp_box_total_item {
    transform: rotate(270deg) scale(1.5) translateX(-10px) translateY(47px);
  }

  .tp_box_secondary_container {
    align-items: unset !important;
  }

}


@media only screen and (max-width:900px) and (min-width:600px) {
  .tp_box_agent_admin_container {
    margin: 0px 0px 10px;
  }

  .tp_box_status_container {
    margin: 0px 0px 10px;
    align-items: end !important;

  }

  .na-table-white-container1 {
    justify-content: center;
  }

  .tp_box_secondary_container {
    align-items: unset !important;
  }
}

@media only screen and (max-width:1200px) and (min-width:599px) {
  .tp_box_status_container_parent_item {
    max-height: 100px !important;
  }

  .user-list-toggle-button-container_parent_item {
    max-height: 40px !important;

  }
}



@media screen and (max-width:600px) {
  .vl {
    display: none;
  }
}

@media screen and (max-width:500px) {
  .MuiDataGrid-toolbarContainer .MuiButton-root {
    color: #000000;
    font-size: 10px !important;
  }
}


/* @media only screen and (max-width:899px) and (min-width:600px) {
  .tp_total_and_tables_container_item{
      width: 70% !important;
  }
  .tp_box_total_item{
      transform: rotate(271deg) scale(1.3) translateX(-15px) translateY(85px);
  }

} */

@media only screen and (max-width:899px) and (min-width:600px) {
  .tp_total_and_tables_container_item {
    width: 81% !important;
  }

  .tp_box_total_item {
    transform: rotate(271deg) scale(1.3) translateX(-15px) translateY(85px);
  }

  .tp_box_secondary_container {
    align-items: unset !important;
  }

}

@media only screen and (max-width:599px) and (min-width:467px) {
  .tp_total_and_tables_container_item {
    width: 80% !important;
  }

  .tp_box_total_item {
    transform: rotate(270deg) scale(1) translateX(-16px) translateY(87px);
  }

  .tp_box_main_container {
    margin-left: 12px;
  }

  .tp_box_secondary_container {
    align-items: unset !important;
  }


}

@media only screen and (max-width:599px) and (min-width:375px) {
  .tp_box_agent_admin_container {
    margin: 0px 0px 10px;
  }

  .tp_box_status_container {
    margin: 0px 0px 10px;
    align-items: end !important;

  }

  .na-main-container .MuiGrid-root {
    width: 97%;
    margin-right: 0px !important;
  }

  .tp_box_secondary_container {
    align-items: unset !important;
  }

}




@media only screen and (max-width:566px) and (min-width:467px) {
  .tp_total_and_tables_container_item {
    width: 80% !important;
  }

  .tp_box_total_item {
    transform: rotate(270deg) scale(1) translateX(-16px) translateY(87px);
  }

  .tp_box_status_label {
    font-size: 13px;
  }

  .na-main-container .MuiGrid-root {
    width: 97%;
    /* margin-right: 5px !important; */
  }

}

@media only screen and (max-width:599px) and (min-width:500px) {
  .user-list-toggle-button-container_parent_item {
    transform: translateX(-20px) !important;
  }

}

@media only screen and (max-width:466px) and (min-width:375px) {
  .tp_total_and_tables_container_item {
    width: 85% !important;
  }

  .tp_box_total_item {
    transform: rotate(270deg) scale(1) translateX(-15px) translateY(96px);
  }

  .tp_box_status_label {
    font-size: 13px !important;
  }

  .tp_box_main_container {
    margin-left: 15px;
  }

  .na-main-container .MuiGrid-root {
    width: 97%;
    margin-right: 0px !important;
  }

  .agent-edit-dlt-menu ul {
    border-right: 3px solid #c8bba15c;
    border-bottom: 3px solid #c8bba15c;
    padding: 0px !important
  }




}

@media only screen and (max-width:375px) and (min-width:320px) {
  .tp_total_and_tables_container_item {
    width: 93% !important;
  }

  .tp_box_total_item {
    transform: rotate(270deg) scale(1) translateX(-20px) translateY(84px);
  }

  .tp_box_status_label {
    font-size: 13px;
  }

  .tp_box_main_container {
    margin-left: 15px;
  }

  .na-main-container .MuiGrid-root {
    width: 97%;
    margin-right: 0px !important;
  }


}

@media only screen and (max-width:599px) and (min-width:320px) {
  .na-agentlist-btn-item {
    margin: 0px 0px 10px 0px !important;
  }
}

@media only screen and (max-width:513px) and (min-width:467px) {

  .tp_box_total_item {
    transform: rotate(270deg) scale(1) translateX(-16px) translateY(97px);
  }

}

@media only screen and (max-width:615px) and (min-width:601px) {
  .na-registration-btn-class {
    font-size: 13px !important;
  }

}

.rowClassName {
  padding-left: 10px !important;
}

@media only screen and (max-width:320px) {
  .na-registration-btn-class {
    font-size: 13px !important;
  }

  .tp_box_status_label {
    font-size: 11px;
  }
}

@media only screen and (max-width:600px) {

  .tp_box_status_container {
    margin-left: 0px !important;
    width: 95% !important;
  }
}

@media only screen and (max-width:500px) {

  .tp_box_status_container {
    margin-left: 6px !important;
  }
}

@media only screen and (max-width:1200px) {
  .user-list-toggle-button-container {
    justify-content: center;
  }
}


@media only screen and (max-width:601px) and (min-width:320px) {
  .na-registration-btn-item {
    margin-bottom: 3px !important;
    margin-right: 0px !important;

  }

  .na-agentlist-btn-item {
    margin-top: 3px !important;
    margin-left: 0px !important;
  }

  .user-list-toggle-button-container {
    justify-content: space-evenly;
  }

  .na-table-white-container2 {
    padding: 10px 5px;
  }

  .na-table-blue-container {
    padding: 10px 10px 10px 15px;
  }

  .tp-total-status-combine-container {
    padding: 0px 0px 0px 0px;
  }

  .na-table-white-container1 {
    justify-content: center;
  }


}

/* Popup design */

.pr-assign-profile-popup {
  justify-content: center;
}

.pr-assign-profile-popup-title-container {
  justify-content: center !important;
  align-items: center;
}

#pr-assign-profile-popup-title {
  font-size: 15px;
  font-weight: 600;
  color: #3B78CE;
}

.pr-assign-profile-popup-close-btn-container {
  justify-content: flex-end;

}

#pr-assign-profile-popup-close-btn {
  cursor: pointer;
  position: absolute;
}

.pr-assign-agent-dropdown-container {
  margin-top: 30px;
  margin-bottom: 0px;
}

.pr-assign-agent-dropdown-label {
  justify-content: center;
  margin: 0px 0px 15px;
}

#pr-assign-agent-dropdown-label {
  font-size: 15px;
  font-weight: 600;
}

.pr-assign-agent-dropdown {
  margin-top: 8px !important;
  width: 350px;
}

.pr-assign-agent-dropdown-box {
  height: 50px;
  background-color: white;
}

.pr-assign-agent-dropdown .MuiFormLabel-root-MuiInputLabel-root {
  font-size: 15px;
}

.pr-save-popup-btn {
  justify-content: end;
  text-align: end;
}

#pr-save-popup-btn {
  border-radius: 10px;
  width: 100%;
  height: 45px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
}

.pr-save-cancel-popup-container {
  justify-content: space-between !important;
  margin: 10px 0px 30px 0px;
}

.pr-assign-agent-dropdown-box .MuiOutlinedInput-notchedOutline {
  border: none;
  box-shadow: 1px 1px 2px 1px rgb(143, 134, 134);
  border-radius: 10px;
}

.assign-profile-checkbox-item .MuiButtonBase-root {
  padding: 0px 4px 0px 5px !important;
}

.assign-profile-checkbox-item .MuiSvgIcon-root {
  margin-bottom: 4px !important;
}

#assign-profile-checkbox-text {
  font-size: 15px;
  font-weight: 600;
  color: gray;
}

.MuiFormControlLabel-root .MuiSwitch-sizeSmall {
  width: 27px !important;
  height: 10px !important;
}