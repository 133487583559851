
.cards a{
    text-decoration: none;
    margin: 10px;
}


.right-side-section-card-app{
    width: 97%;
    height: 320px;
    border-radius: 10px;
    margin: 0px auto;
    background: #f5f5f5;
    /* margin: 10px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.right-side-section-header{
    margin: 0px 26px !important;
    border-bottom: 1px solid #e2e2e2 !important;
    padding-top: 11px !important;
}
.right-side-section-team{
    padding-left: 0px !important;
    padding-bottom: 10px !important;
    margin-bottom: 0px !important;
}
.right-side-section-card-app-header{
    width: calc(100% - 20px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #c8bba1;
    height: 40px;
    padding: 15px 10px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    position: relative;
}

.right-side-section-card-app-header img{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 15px;
}

.right-side-section-card-app-header h4{
    font-weight: 600;
    color: #1c2735;
}

.right-side-section-card-app-description{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 250px;
}

.right-side-section-container{
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 20px;
    padding-left: 0px;
    padding-right: 0px;
}




.dashboard_page .right_section{
    max-width: 21%;
    min-width: 21%;
    /* margin-right: 15px; */
    height: fit-content;
    /* background-color: rgba(255,255,255,0.7); */
    background-color: #fff;
    /* opacity: 85%; */
    /* box-shadow: 0 0 15px rgb(0 0 0 / 5%); */
    border-radius: 5px;
}
.btn_your_team{
    height: 25px;
    background-color:rgb(200, 187, 161) ;
    color: black;
    border-right: hidden;
    border-radius: 35px;
    border-color:rgb(200, 187, 161)  ;
    border-bottom: rgb(200, 187, 161) ;
    margin-bottom: 10px;
    padding: 5px;
    margin-right: 10px;
    font-weight: 600;
    cursor: pointer;
}

.dashboard_page .right_section .content{
    width: calc(100% - 30px);
    padding: 30px 15px;
    text-decoration: none;
}

.dashboard_page .right_section .content .leaderboard_content{
    width: 100%;
    height: 370px;
}

.dashboard_page .right_section .content .leaderboard_content h4{
    font-size: 21px;
    font-weight: 600;
    color: #1c1c1c;
    margin-bottom: 15px;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 10px;
}

.dashboard_page .right_section .content .leaderboard_content .rank_table{
    overflow-y: auto;
    border: 1px solid #ddd;
    width: 100%;
    height: 280px !important;
    overflow: scroll !important;
}

.dashboard_page .right_section .content .leaderboard_content .rank_table thead{
    max-height: 40px !important;
}

.dashboard_page .right_section .content .leaderboard_content .rank_table thead tr{
    background-color: #f5f5f5;
    max-height: 40px !important;
}

.dashboard_page .right_section .content .leaderboard_content .rank_table thead tr th,
.dashboard_page .right_section .content .leaderboard_content .rank_table tbody tr td{
    padding: 10px;
    height: 10px !important;
    font-weight: bold;
    font-size: medium;
}

.dashboard_page .right_section .content .leaderboard_content .rank_table tbody tr td{
    font-weight: 500;
    text-align: center;
}

.dashboard_page .right_section .content .leaderboard_content .rank_table thead tr th:nth-child(1),
.dashboard_page .right_section .content .leaderboard_content .rank_table tbody tr td{
    width: 13.33%;
}

.dashboard_page .right_section .content .leaderboard_content .rank_table thead tr th:nth-child(2),
.dashboard_page .right_section .content .leaderboard_content .rank_table tbody tr td{
    width: 53.33%;
}

.dashboard_page .right_section .content .leaderboard_content .rank_table thead tr th:nth-child(3),
.dashboard_page .right_section .content .leaderboard_content .rank_table tbody tr td{
    width: 33.33%;
}

/* @media  screen and (min-width:768px) and (max-width:768px) {
    .dashboard_page .right_section {
        width: 100%;
        max-width: 0;
        min-width: 77%;
        height: 495px;
    }
    
}  */

@media  screen and (min-width:1521px) and (max-width:1700px) {
    .dashboard_page .right_section {
        width: 100%;
        /* margin-top: 70px */
        
    }
    .dashboard_page .right_section .name_of_member{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
    }
    
}
@media  screen and (min-width:1701px) and (max-width:2000px) {
    .dashboard_page .right_section {
        width: 100%;
        
    }
}
@media  screen and (min-width:1521px) and (max-width:1559px) {
    .dashboard_page .right_section {
        max-width: 19%;
        min-width: 19%;
    }
}

/* @media screen and (min-width:1127px) and (max-width:1200px) {
    .dashboard_page .right_section {
        position: absolute !important;
        left: 0%  !important;
        top: 22% !important;
        max-width: 31% !important;
    }   
} */
@media screen and (min-width: 1022px) and (max-width: 1127px){
    .dashboard_page .right_section {
        min-width: 31% !important;
        position: absolute;
        top: 12%;
        left: 0%;
    }
}
@media screen and (min-width: 821px) and (max-width: 1021px){
    .dashboard_page .right_section {
        max-width: 39% !important;
        position: absolute;
        top: 12%;
        left: 0%;
    }
}
@media screen and (min-width: 820px) and (max-width: 899px){
    /* .dashboard_page .right_section {
        max-width: 41% !important;
        position: absolute;
        top: 12%;
        left: 0%;
    } */
}
/* @media screen and (max-width: 1325px) {
    .dashboard_page{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;
        min-height: 100vh;
    }
    .dashboard_page .right_section{
        position: absolute;
        left: 0;
        top: 45%;
        width: 30% !important;
        min-width: 30% !important;
        max-width: 30% !important;
    }
} */
/* @media screen and (max-width: 1200px) {
    .dashboard_page .right_section{
        position: relative;
        width: 45% !important;
        min-width: 45% !important;
        max-width: 45% !important;
    }
} */
@media screen and (max-width: 1325px) {
    .dashboard_page .right_section{
        position: relative;
        /* width: 100% !important;
        min-width: 40% !important;
        max-width: 40% !important; */
        /* top: -12px; */
    }
}
@media screen and (min-width:0px) and (max-width:420px) {
    .dashboard_page .right_section {
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
    }
    .dashboard_page .right_section .content{
        padding: 0;
        width: 100%;
    }
    .global_header .right_section {
        display: flex !important;
        justify-content: flex-start;
        align-items: center;
        
        
    }
    .dashboard_page .right_section .content .leaderboard_content{
        height: 0px;
    }
    .dashboard_page .right_section{
        margin-left: 0px;
        /* margin: 10px; */
    }
   
    .right_section{
        text-align: center;
       
    }
    .btn_your_team{
        height: 25px;
        background-color:rgb(200, 187, 161) ;
        color: black;
        border-radius: 35px;
        border-color:rgb(200, 187, 161)  ;
        border-bottom: rgb(200, 187, 161) ;
        margin-bottom: 10px;
        padding: 5px;
        margin-right: 10px;
        font-weight: 600;
    }
}
@media screen and (min-width:421px) and (max-width:820px) {
    .dashboard_page .right_section {
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
    }
    .global_header .right_section {
        display: flex !important;
        justify-content: flex-start;
        align-items: center;
    }
    
}

@media screen and (min-width:1200px) and (max-width:1400px) {
   .dashboard_page  .right_section {
          width: 25% !important;
          min-width: 20% !important;
          max-width: 25% !important;
      }
  }
  @media screen and (min-width:821px) and (max-width:1199px) {
    .dashboard_page  .right_section {
           width: 47% !important;
           min-width: 20% !important;
       max-width: 47% !important;
       }
   }