.middle_header{
    font-size: medium;
    justify-content: space-between;
    padding-bottom: 1px;
    height: 57px;
    align-items: end;
    display: flex;
    /* margin-bottom: 18px; */
    font-size: 21px;
    font-weight: 600;
    color: #1c1c1c;
}
.upload_tool{
    padding-left: 10px;
    padding-bottom: 10px;
}
.dashboard_upload_tool{
    font-size: medium;
   text-decoration: none;
    padding-bottom: 10px;
    
}
.middle{
    padding: 6px 15px 30px 15px !important;
    height: 821px !important;
}
.left_upload_tool {
    height: 838px  !important;
}
.right_upload_tool{
    height: 91vh !important;
    
    
}

.dashboard_upload_tool{
    
    display: flex;
    justify-content: end;
    margin-right: 45px;
    margin-top: 5px;
    text-decoration: none;
    color: black;
    --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    font-family: var(--font-family-sans-serif);
    text-transform: none;
    font-weight: 500;
    font-size: medium;
}
.dashboard_page_upload_tool{
    min-height: 100vh;
}

iframe .tv-embed-widget-wrapper__body {
    height: 52%;
}
.upload_tool_ifame{
    height: 700px !important;
}
.sidebar-box-area_upload_tool{
    height: 513px !important;
}


