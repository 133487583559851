.MuiDataGrid-columnHeaders {
  background-color: #c8bba1 !important;
}

.MuiDataGrid-columnHeaderTitle {
  text-transform: uppercase;
  font-weight: bold !important;
}

/* .MuiDataGrid-columnHeadersInner {
  flex: 1 !important;
} */

 .MuiDataGrid-columnHeader:nth-child(1) {
  padding: 0 !important;
} 

.tp_box_status_container_parent_item{
  margin-left: 3px;
}

.MuiDataGrid-row {
  width: 100% !important;
  /* padding: 0.5rem 0; */
}

.MuiDataGrid-row:nth-child(even) {
  background-color: rgba(200, 187, 161, 0.18);
  min-width: 100% !important;
}

/* .MuiDataGrid-virtualScrollerRenderZone {
  width: 100%;
} */

.MuiDataGrid-cell--textLeft {
  display: flex !important;
  /* justify-content: center !important; */
  align-items: center !important;
}

/* .MuiDataGrid-cell--textLeft:nth-child(2) {
  justify-content: flex-start !important;
} */

.MuiSelect-select {
  z-index: 1000;
  padding: 0 !important;
  padding: 0.3rem 0.5rem !important;
}

.MuiSvgIcon-root {
  z-index: 100;
}
.MuiOutlinedInput-notchedOutline {
  border: 0px !important;
}

.MuiSelect-select:nth-child(even) {
    background-color: rgba(200, 187, 161, 0.18) !important;
}
/* .MuiFormControl-fullWidth {
  border: 1px solid #fff !important;
}

.MuiSelect-outlined {
  border: 1px solid #fff !important;
}

.Mui-focused {
  border: 1px solid #fff !important;
} */
.MuiDataGrid-cell:nth-child(1) {
  padding: 0 !important;
}


.pendingusers_select {
  background-color: pink !important;
}

.pendingusers_main #ghoogle-chat-btn {
  display: none !important;
}

.hide-chat-button #ghoogle-chat-btn{
  display: none !important;
}

[data-field = "delete"] {
  justify-content: center !important;
}

.MuiFormGroup-root {
  flex-direction: row !important;
}