
.link_training{
    padding-left: 180px !important;
}
.dashboard_page_training, .dashboard_page{
    min-height: 100vh !important;
}
.main_header{
    width: 55%;
    background-color: #fff;
    opacity: 85%;
    min-height: 100vh !important;
}
.videos{
    height: 47px;
   align-items: end;
    display: flex;
    justify-content: space-between;
    padding: 10px;--font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    font-family: var(--font-family-sans-serif);
    font-size: 21px;
    font-weight: 600;
    color: #1c1c1c;
    margin-bottom: 15px;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 10px;
    width: 94%;
    margin: 0px 20px;
}
.video1, .video2{
    width: 500px !important;
    height: 290px !important;
    margin: 10px;
    
}

.video_content {
    flex-wrap: wrap !important;
        justify-content: center !important;
        display: flex;
        flex-direction: column;
        align-items: center !important;
        width: 100% !important;
    /* width: 50%; */
}
.video_div1,.video_div2{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}


.dashboard,.dashboard_link2{
    display: flex;
    justify-content: space-around;

    /* margin-right: 45px; */
    margin-top: 5px;
    text-decoration: none;
    color: black;
    --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    font-family: var(--font-family-sans-serif);
    text-transform: none;
    font-weight: 500;
    font-size: medium;
}
.link{
    text-decoration: none;
    /* padding-left: 872px; */
    width: 10px;
}

.chatbox{
    font-size: medium;
    /* margin-left: 195px; */
    padding-bottom: 10px;
}
h3{
    padding-top: 15px;
    padding-bottom: 10px;
}
/* a{
    display: flex;
    justify-content: center;
} */
p{
    width: 70%;
    /* margin-left: 15px; */
    text-align: justify;
}
.tittle{
    width: 100%;
    display: flex;
    justify-content: center;
}

.Link_pdf1{
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap !important;
    margin-bottom: 20px;
    position: relative;
}
.Link_pdf1 a{
    text-decoration: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 496px;
    height: 279px;
}
.Link_pdf1 a .one,
.Link_pdf1 a .two,
.Link_pdf1 a .three{
    width: 496px;
    height: 279px;
    background-repeat: no-repeat !important;
    border: 1px solid #C8BBA1;
    opacity: 1;
    background-color: #fff !important;
    background-blend-mode: multiply !important;
    background-position: center top !important;
    background-size: cover !important;
}
.Link_pdf1 a .one:hover,
.Link_pdf1 a .two:hover,
.Link_pdf1 a .three:hover{
    opacity: 0.9;
    background-color: #000a !important;
    background-blend-mode: multiply !important;
}
.Link_pdf1 a .one{
    background: url("../../assets/images/Asset\ Elevation.png");
}

.Link_pdf1 a .two{
    background: url("../../assets/images/Buy\ Sell\ Agreements.png");
}
.Link_pdf1 a .three{
    background: url("../../assets/images/4MostImportantConsiderationsForPremiumFinance.png");
}

.Link_pdf1 .oneText{
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    color: #000;
    text-align: center;
    font-weight: 600 !important;
    font-size: 20px;
    margin-top: 20px !important;
}
.Link_pdf1 .asset_elevation_download_icon,
.Link_pdf1 .buy_sell_agreement_download_icon{
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    /* justify-content: center !important; */
    align-items: center !important;
    text-align: center !important;
    display: none !important;
    transition: 0.3s;
}

.Link_pdf1 a .one:hover .asset_elevation_download_icon,
.Link_pdf1 a .two:hover .buy_sell_agreement_download_icon{
    color: #fff !important;
    display: block !important;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50% !important;
    position: relative;
    font-size: 20px;
}

.Link_pdf1 a .one:hover .asset_elevation_download_icon svg,
.Link_pdf1 a .two:hover .buy_sell_agreement_download_icon svg{
    width: 20px !important;
    height: 20px !important;
    transition: 0.3s;
}

@media screen and (min-width:0px) and (max-width:420px) {
    .main_header{
        width: 90%;
        margin-left: 0px;
    }
    .dashboard_page {
       height: max-content;
    }
    .link {
        text-decoration: none;
        padding-left: 145px;
        width: 10px;
    }
    .video1, .video2{
        width: 300px;
        height: 150px;
        margin: 10px;
    }
    p {
        width: 80%;
        text-align: justify;
    }
    .video_content{
        flex-direction: column;
    }
    .chatbox {
        font-size: medium;
        margin-left: 180px;
        padding-bottom: 10px;
    }
    .header {
        height: 30px;
        margin-top: 25px;
    }
    .video_div1,.video_div2 {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    .videos{
        width: 86%;
    }
    .video1, .video2, .one, .two
    {
        width: 300px !important;
        height: 150px !important;
        margin: 10px;
    }
    .dashboard,.dashboard_link2{
        font-weight: 500;
        font-size: 14px;
    }
    .Link_pdf1 a{
        height: 150px !important;
    }
    .one, .two{
        margin: 0 !important;
    }
   
}
@media screen and (min-width:421px) and (max-width:625px) {
    .video1, .video2, .one, .two{
        width: 300px !important;
        height: 200px !important;
        margin: 10px;
    }
    .Link_pdf1 a{
        height: 200px !important;
    }
    .one, .two{
        margin: 0 !important;
    }
    p {
        width: 80%;
        text-align: justify;
    }
    .video_div1,.video_div2 {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .one .oneText, .two .oneText{
        margin-top: 10px !important;
    }
}
@media screen and (min-width:421px) and (max-width:820px) {
    .main_header{
        width: 90%;
    }
    .dashboard_page {
        height: max-content;
     }
    .link {
        text-decoration: none;
        padding-left: 507px;
        width: 10px;
    }
    .video_content {
        display: flex;
        flex-direction: column;
        /* width: 50%; */
    }
    .video1, .video2{
        width: 300px;
        height: 150px;
        margin: 10px;
    }
    .chatbox {
        font-size: medium;
        /* margin-left: 421px; */
        padding-bottom: 10px;
    }
    .header {
        height: 30px;
        margin-top: 25px;
    }
    .video_div1,.video_div2 {
        margin-left: 6%;
        /* height: 300px; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .videos {
        width: 87%;
    }
    .dashboard_page .middle_section .container .cards {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
    }
   
}

@media screen and (min-width: 821px) and (max-width:1500px) {
    .video_content {
        flex-direction: column;
    }
    .main_header {
        width: 55%;
        /* width: 45% !important; */
       
    }
    
   
}
@media screen and (min-width: 821px) and (max-width:1300px) {
    .video_div1, .video_div2 {
        margin-left: 0%;
        flex-direction: column;
        
    }
    .video1, .video2{
        width: 400px;
        height: 290px;
        margin: 10px;
    }
}

@media screen and (min-width: 1501px) and (max-width: 1860px){
    .video_content{
        flex-wrap: wrap !important;
        justify-content: center !important;
        display: flex;
        flex-direction: column;
        align-items: center !important;
        width: 100% !important;
    }
    .video_content div{
        width: 100% !important;
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin: 0 auto;
    }
    .video_content div a{
        width: 100% !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
        margin: 0 auto;
    }
    .video_content div a iframe{
        margin: 0 auto !important;
    }
    .main_header{
        width: 55%;
    }
    p {
        width: 70%;
        text-align: justify;
    }
}
@media screen and (min-width: 1501px) and (max-width: 1620px) {
    .main_header{
        width: 50%;
    }
    p {
        width: 70%;
        text-align: justify;
    }
}
@media screen and (max-width: 1325px) {
    .main_header{
        margin-bottom: 20px;
    }
}
@media screen and (min-width: 1200px) and (max-width:1400px) {
    .main_header {
        width: 45% !important;
    }
}
@media screen and (min-width:421px) and (max-width: 1400px) {
    .video_div1 p, .video_div2 p{
        width: 80%;
    }
}