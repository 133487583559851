.input_tag_confirm_mail {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #c8bba1;
  opacity: 50%;
}

.register_main {
  font-family: 'Open Sans' !important;
}

.register_form_confirmmail {
  width: 40%;

  margin-bottom: 20px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  border: 2px solid #c8bba1;
  height: fit-content;
}

/* input {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #c8bba1;
    opacity: 50%;
  } */
.first_item_confirmMail {
  /* display: flex;
  flex-wrap: wrap; */
  /* justify-content: flex-start; */
  /* flex-direction: row; */
  display: flex;
}

.field_name_confirmMail .css-ahj2mt-MuiTypography-root {
  width: 50%;
}

.field_name_confirmMail .input_tag_confirm_mail {
  width: 50% !important;
}

.field_name_confirmMail {
  width: 16%;
  display: flex;
  align-items: center;
}

.confirm_mail_innerBox {
  font-size: 25px;
  font-weight: 600;
  width: 800px;
  height: 500px;
  margin-top: 60px !important;
  border-radius: 5px;
  border: 1px solid #C8BBA1;
  background: rgba(200, 187, 161, 0.1);
  backdrop-filter: blur(5px);

}

.reset_password_btn {
  width: calc(100% - 30px) !important;
  background: rgba(200, 187, 161, 1) !important;

}

.inputField1 {
  background: white;
  border-radius: 5px;
  height: 50px;
  width: 100%;
  border: none;
}

.inputField1 .MuiOutlinedInput-input {
  padding: 14px 14px 15px 0px;
}
.confirm_main_form_container{
width: 470px !important;
}

@media screen and (max-width:1300px) {

  .confirm_mail_innerBox {
    width: 700px !important;
  }


}

@media screen and (max-width:950px) {
 

  .confirm_main_form_container {
    width: 400px  !important;
  }
  .confirm_mail_innerBox {
    width: 600px !important;
  }


}

@media screen and (max-width:750px) {

  .confirm_main_form_container {
    width: 350px  !important;
  }
  .confirm_mail_innerBox {
    width: 550px !important;
  }


}
@media screen and (max-width:650px) {


  .confirm_mail_innerBox {
    width: 450px !important;
  }


}
@media screen and (max-width:530px) {

  .confirm_main_form_container {
    width: 320px  !important;
  }
  .confirm_mail_innerBox {
    width: 400px !important;
  }
  .confirm_mail_innerBox{
    height: 350px;
  }


}
/* @media screen and (max-width:500px) {


  .confirm_mail_innerBox {
    width: 350px !important;
  }


} */


@media screen and (max-width:450px) {

  .confirm_main_form_container {
    width: 280px  !important;
  }
  .confirm_mail_innerBox {
    width: 330px !important;
  }


}