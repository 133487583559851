*, ::after, ::before{
    box-sizing: unset !important;
}



.global_header{
    background: #c8bba1;
    height: 55px;
    box-shadow: 0 0 2px rgb(0 0 0);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    /* width: calc(100% - 30px); */
    width: calc(100% - 5px);
}
.global_header .left_section{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.global_header .left_section .first_image_logo,
.global_header .left_section .second_image_logo{
    width: 155px;
    height: 48px;
}
.global_header .right_section{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 20px !important;
}
.global_header .right_section .user_logo{
    width: 41px;
    height: 41px;
    border-radius: 50%;
}
.link_header{
    width: 50px;
    margin-right: 10px;
}
.dashboard{
    display: flex;
    justify-content: end;
    text-decoration: none;
    color: black;
    --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    font-family: var(--font-family-sans-serif);
    text-transform: none;
    font-weight: 500;
    font-size: medium;
}
.right_section .MuiAvatar-root{
    width: 35px !important;
    height: 35px !important;
}

.account-menu .account-menu{
}
.account-menu .MuiList-root{
    padding: 4px 0px  !important;
}
@media screen and (max-width:480px) {
    .global_header .left_section .first_image_logo, .global_header .left_section .second_image_logo {
        width: 100px !important;
        height: 48px !important;
    } 
}
@media screen and (max-width:600px) {
 
    .account-menu .account-menu{
        padding: 0px 16px !important;
    }
    .account-menu .MuiList-root{
        padding: 0px  !important;
    }
    .account-menu .MuiMenuItem-root{
        padding: 0px 16px !important;
    }


   
    
}