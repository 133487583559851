.dashboard_page .left_section {
    width: 330px;
    /* height: 94vh; */
    height: max-content;
    /* background-color: rgba(255,255,255,0.7); */
    background-color: #fff;
    /* opacity: 85%; */
    border-radius: 5px;
    padding: 20px 15px 25px 15px;
    /* margin-right: 6px; */
  
}
.dashboard_page .left_section .btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.dashboard_page .left_section{
    padding: 10px;
}
.btn_div{
    /* padding: 10px; */
    position: absolute;
    top: 30%;
    left: 1%;
    width: 18%;
    background: #fff;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
}
.btn{
    height: 30px;
    color:#fff;
    background-color: #1c2735;
    border-color: #1c2735;
}

.dashboard_page .left_section .btn-success {
    color: #fff;
    background-color: #1c2735;
    border-color: #1c2735;
}

.dashboard_page .left_section .page-heading{
    font-size: 21px;
    font-weight: 600;
    color: #1c1c1c;
    margin-bottom: 15px;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 10px;
}

@media screen and (min-width:0px) and (max-width:420px) {
    
    .dashboard_page .left_section .sidebar-box-area{
        padding: 30px;  
    }  
    .dashboard_page .left_section {
        width: 100% !important;
    }
    .dashboard_page .left_section .btn-success
    {
        width: 90% !important;
        margin-left: 5%;
    } 
    .btn_div{
        top: 11.5%;
        left: 5%;
        width: 90%;
    }
     
}
@media screen and (min-width:421px) and (max-width:820px) {
    
    
    .dashboard_page .left_section .sidebar-box-area{
        min-width: 94%;
    }
     .dashboard_page .left_section .btn-success {
        width: 40% !important;
    margin-left: 31%;
    }
    
    .dashboard_page .left_section {
        width: 100%;
        height: min-content !important;
        /* min-width: 700px; */
        padding: 34px;
    }
    .btn_div{
        top: 11%;
        left: 5%;
        width: 92%;
    }
   
}

@media screen and (min-width:821px) and (max-width:921px) {
    .dashboard_page .left_section {
        width: 40%;
    } 
    .btn_div {
        top: 34% !important;
        left: 2% !important ;
        width: 38% !important;
    } 
}
@media screen and (min-width:922px) and (max-width:1121px) {
    .dashboard_page .left_section {
        width: 38%;
    }  
}
@media screen and (min-width:1022px) and (max-width:1321px) {
    .dashboard_page .left_section {
        width: 30%;
    }  
}
/* @media screen and (min-width:1322px) and (max-width:1410px) {
    .dashboard_page .left_section {
        width: 30%;
    }  
} */
@media screen and (min-width:1322px) and (max-width:1520px) {
    .dashboard_page .left_section {
        width: 20%;
    }  
}
/* @media  screen and (min-width:820px) and (max-width:820px) {
    .dashboard_page .left_section {
        height: 43vh !important;
    }
    
    
} */
@media  screen and (min-width:768px) and (max-width:768px) {
    /* .dashboard_page .left_section {
        height: 49vh !important;
    } */
    /* .dashboard_page .left_section {
        height: 47vh !important;
    }
     */
}  
/* @media screen and (min-width: 1200px) and (max-width: 1322px){
    .dashboard_page .left_section { */
        /* width: 73%; */
        /* width: 20% !important;
    }
} */
@media screen and (min-width: 820px) and (max-width: 1300px){
    .dashboard_page .left_section {
        position: inherit;
        /* min-width: 40% !important;
        max-width: 40% !important; */
    }
}
@media screen and (min-width:1200px) and (max-width:1400px) {

    .dashboard_page .left_section{
        width: 25% !important;
        margin-left: 15px;
    }
    
}
@media screen and (min-width:821px) and (max-width:1199px) {

    .dashboard_page .left_section{
        max-width: 51% !important;
        width: 47%;
        margin-left: 15px;
    }
    
}
@media screen and (min-width:0px) and (max-width:820px){
    .dashboard_page .left_section {
        margin-top: 10px;
    }
    
}