.dashboard_page {
    width: calc(100% - 30px);
    display: flex;
    flex-wrap: wrap;
    position: relative;
    top: 50px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-family: var(--font-family-sans-serif);
    padding: 15px;
    background-image: url("../../assets/images/bg_img2.png");

    justify-content: space-around;
    min-height: 100vh !important;
    /* background-color: #f5f5f5; */
}

.team {
    padding-left: 10px;
    padding-bottom: 10px;
}

.card_app_description span {
    width: 200px;
    height: 200px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.card_app_description img {
    height: 100px;
    width: 100px;
}



.dashboard_page .middle_section {
    width: 56%;
    /* margin-left: 15px;
    margin-right: 8px; */
    background-color: rgba(255, 255, 255, 0.7);
    /* opacity: 85%; */
    height: auto;
    border-radius: 4px;
    padding: 30px 15px 30px 15px;

}

.dashboard_page .middle_section .middle_section_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 10px;
}

.dashboard_page .middle_section .middle_section_header h4 {
    font-size: 21px;
    font-weight: 600;
    color: #1c1c1c;
}

.dashboard_page .middle_section .middle_section_header span {
    font-size: 14px;
    font-weight: normal;
    color: #65b14d;
    cursor: pointer;
}

.dashboard_page .middle_section .container {
    width: 100%;
    padding-right: 15px;
    /* padding-left: 15px; */
    margin-right: auto;
    margin-left: auto;
}

.dashboard_page .middle_section .container ul {
    margin-bottom: 1rem !important;
    display: flex;
    justify-content: center !important;
    flex-direction: row;
    list-style: none;
}

.dashboard_page .middle_section .container ul li {
    margin: 0 20px;
}

.sidebar-box-area {
    padding-top: 18px;
    padding: 20px 15px 10px 15px;
}

.sidebar-box-area-height {
    padding-top: 10px !important;
}

.dashboard_page .middle_section .container ul li a {
    color: #fff;
    text-transform: uppercase;
    background-color: #1c2735;
    border-radius: 0.25rem;
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    outline: none;
    transition: .5s;
    cursor: pointer;
}

.dashboard_page .middle_section .container .cards {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.dashboard_page .middle_section .container .cards a {
    text-decoration: none;
    margin: 10px;
}


.dashboard_page .middle_section .container .cards .card_app {
    width: 320px;
    height: 320px;
    border-radius: 10px;
    background: #f5f5f5;
    /* margin: 10px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.right-side-section-container .card_app {
    width: 320px;
    height: 320px;
    border-radius: 10px;
    background: #f5f5f5;
    /* margin: 10px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.dashboard_page .middle_section .container .cards .card_app .card_app_header {
    width: calc(100% - 20px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #c8bba1;
    height: 40px;
    padding: 15px 10px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    position: relative;
}

.right-side-section-container .card_app_header {
    width: calc(100% - 20px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #c8bba1;
    height: 40px;
    padding: 15px 10px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    position: relative;
}

.dashboard_page .middle_section .container .cards .card_app .card_app_header img {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 15px;
}

.right-side-section-container .card_app .card_app_header img {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 15px;
}

.dashboard_page .middle_section .container .cards .card_app .card_app_header h4 {
    font-weight: 600;
    color: #1c2735;
}

.right-side-section-container .card_app .card_app_header h4 {
    font-weight: 600;
    color: #1c2735;
}

.dashboard_page .middle_section .container .cards .card_app .card_app_description {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 250px;
}

.right-side-section-container .card_app .card_app_description {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 250px;
}

.chatbox {
    font-size: medium;
    /* margin-left: 195px; */
    padding-right: 10px;
}

.dashboard_page .middle_section .container .cards .card_app .card_app_description svg {
    fill: #1c2735;
}


.tradingview-widget-copyright {
    font-size: 13px !important;
    line-height: 32px !important;
    text-align: center !important;
    vertical-align: middle !important;
    font-family: 'Trebuchet MS', Arial, sans-serif !important;
    color: #9db2bd !important;
}

.tradingview-widget-copyright .blue-text {
    color: #2962FF !important;
}

.tradingview-widget-copyright a {
    text-decoration: none !important;
    color: #9db2bd !important;
}

.tradingview-widget-copyright a:visited {
    color: #9db2bd !important;
}

.tradingview-widget-copyright a:hover .blue-text {
    color: #1E53E5 !important;
}

.tradingview-widget-copyright a:active .blue-text {
    color: #1848CC !important;
}

.tradingview-widget-copyright a:visited .blue-text {
    color: #2962FF !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    background-color: #1c2735;
    padding: 10px;
    letter-spacing: 0.5px;
    max-width: 320px;
    font-size: 15px;
}

.container {
    margin-top: -10px;
}

.MuiTooltip-popper .MuiTooltip-tooltip .MuiTooltip-arrow {
    color: #1c2735;
}

.img {
    display: flex;
    flex-direction: column;
    width: 120px;
    height: 120px;
    border-radius: 3px;
}

.img img {
    border-radius: 9px;
}

.name_of_member {
    margin-top: 20px;
    height: auto;
    display: flex;
    flex-direction: row !important;
    padding: 10px;

}

.header {
    height: 56px;
    align-items: end;
    display: flex;
    /* margin-bottom: 18px; */
    font-size: 21px;
    font-weight: 600;
    color: #1c1c1c;
    justify-content: space-between;
}

.name {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    margin-left: 15px;
}

.occupation {
    font-style: italic;
    margin-left: 15px;
}

.first_div,
.second_div {
    display: flex;
    flex-direction: row;
}

.first_box_br {
    width: 69%;
    padding: 5px;
    padding: 10px;
}

.second_box_br {
    padding: 5px;
    padding: 10px;
}

.br_box {
    border: 2px solid rgb(211, 211, 211);
    border-bottom: 1px solid rgb(211, 211, 211) !important;
    background-color: #fff;
}

.second_box_br {
    text-align: center;
}

@media screen and (min-width:0px) and (max-width:420px) {
    .dashboard_page {
        height: auto;
        /* height: 3912px; */
        padding: 10 5 10 5px;
        width: calc(100% - 30px);
        justify-content: center;
        padding: 15px;

    }

    .dashboard_page .middle_section .container .cards {
        justify-content: center;
    }

    .header {
        height: 30px;
    }

    .global_header {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .dashboard_page .middle_section .container ul {
        flex-direction: column;
    }

    .dashboard_page .middle_section .container {
        padding-left: 0;
        padding-right: 0;
    }

    .dashboard_page .middle_section {
        width: 88%;
        margin: 10px;
        /* margin-top: 60px; */
        padding: 28px 15px 30px 10px;

    }

    @media screen and (max-width:420px) {
        .dashboard_page .middle_section .container .cards .card_app {
            width: 263px;
            height: 320px;
            border-radius: 10px;
            background: #f5f5f5;
            margin: 10px;
        }
    }

    .dashboard_page .middle_section .container .cards .card_app {
        width: 263px;
        height: 320px;
        border-radius: 10px;
        background: #f5f5f5;
        margin: 10px;
    }

}

@media screen and (min-width:421px) and (max-width:820px) {
    .dashboard_page {

        height: auto;
        padding: 10 5 10 5px;
        width: calc(100% - 30px);
        justify-content: center;
        padding: 15px;
    }

    .dashboard_page .middle_section .container .cards .card_app {
        width: 263px;
        height: 320px;
        border-radius: 10px;
        background: #f5f5f5;
        margin: 10px;
    }

    .dashboard_page .middle_section .container ul li a {
        width: 40%;
        margin-left: 27%;
    }

    .dashboard_page .middle_section {
        width: 84%;
        height: auto;
        margin-left: 0;
        /* margin: 10px; */
        /* margin-top:80px */
    }

    .dashboard_page .middle_section .container ul {
        flex-direction: column;
        /* width: 40%;
        margin-left: 236px; */
    }

    .dashboard_page .middle_section .container {
        padding-left: 0;
    }

    .dashboard_page .middle_section .container ul li {
        padding-top: 15px;
    }
}

@media screen and (min-width:820px) and (max-width:820px) {

    .dashboard_page .middle_section {
        width: 73%;
    }

}

@media screen and (min-width:821px) and (max-width:921px) {
    .dashboard_page .middle_section {
        width: 50%;
    }
}

@media screen and (min-width:922px) and (max-width:1121px) {
    .dashboard_page .middle_section {
        width: 53%;
    }

    .dashboard_page .middle_section .container .cards {
        justify-content: center;
    }

    .dashboard_page {
        min-height: 100vh !important;
    }
}

@media screen and (min-width:1022px) and (max-width:1321px) {
    .dashboard_page .middle_section {
        width: 62%;
    }

    .dashboard_page .middle_section .container .cards {
        justify-content: center;
    }

    .dashboard_page {
        height: auto;
    }
}

@media screen and (min-width:1322px) and (max-width:1520px) {
    .dashboard_page .middle_section {
        /* width: 73%; */
        width: 51%;
    }

    .dashboard_page .middle_section .container .cards {
        justify-content: center;
    }

    .dashboard_page {
        height: auto;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1322px) {
    .dashboard_page .middle_section {
        /* width: 73%; */
        width: 50%;
    }
}

@media screen and (min-width:768px) and (max-width:768px) {
    .dashboard_page .middle_section {
        width: 73%;
    }


}

@media screen and (min-width:1560px) and (max-width:1600px) {
    .dashboard_page .middle_section {
        width: 51% !important;
    }
}

@media screen and (min-width:1521px) and (max-width:1700px) {
    .dashboard_page .middle_section {
        width: 52%;
    }
}

@media screen and (min-width:1701px) and (max-width:1873px) {
    .dashboard_page .middle_section {
        width: 53%;
    }
}

@media screen and (min-width:1874px) and (max-width:2000px) {
    .dashboard_page .middle_section {
        width: 56% !important;
    }

    .dashboard_page .middle_section .container .cards {
        display: flex;
        justify-content: center;
    }
}



@media screen and (max-width: 1300px) {
    .main_header {
        width: 100%;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 1300px) {
    .dashboard_page .middle_section {
        width: 100%;
        margin-bottom: 20px;
    }
}


@media screen and (min-width:1200px) and (max-width:1400px) {
    .middle_section {
        width: 40% !important;
    }

    .dashboard_page .left_section {
        width: 44% !important;
        margin-left: 15px;
    }

}