.resetpass_inputField {
  background: white;
  border-radius: 5px;
  height: 50px;
  width: 100% !important;
  border: none;

}

.resetpass_innerBox {
  font-size: 25px;
  font-weight: 600;
  width: 800px;
  height: 500px;
  margin-top: 60px !important;
  border-radius: 5px;
  border: 1px solid #C8BBA1;
  background: rgba(200,187,161,0.1);
  backdrop-filter: blur(5px);
  
  }
  .resetpass_form_container{
    width: 470px;
  }

  .resetpass_btn{
    width: calc(100% - 15px) !important;
    height: 40px !important;
    background: rgba(200, 187, 161, 1) !important;
   
  
  }
  .resetpass_form_container{
    
  }


  @media screen and (max-width:1300px) {

    .resetpass_innerBox {
      width: 700px !important;
    }
  
  
  }
  
  @media screen and (max-width:950px) {
   
  
    .resetpass_form_container {
      width: 400px  !important;
    }
    .resetpass_innerBox {
      width: 600px !important;
    }
  
  
  }
  
  @media screen and (max-width:750px) {
  
    .resetpass_form_container {
      width: 350px  !important;
    }
    .resetpass_innerBox {
      width: 550px !important;
    }
  
  
  }
  @media screen and (max-width:650px) {
  
  
    .resetpass_innerBox {
      width: 450px !important;
    }
  
  
  }
  @media screen and (max-width:530px) {
  
    .resetpass_form_container {
      width: 320px  !important;
    }
    .resetpass_innerBox {
      width: 400px !important;
    }
  
  
  }
  /* @media screen and (max-width:500px) {
  
  
    .resetpass_innerBox {
      width: 350px !important;
    }
  
  
  } */
  
  
  @media screen and (max-width:450px) {
  
    .resetpass_form_container {
      width: 280px  !important;
    }
    .resetpass_innerBox {
      width: 330px !important;
    }
  
  
  }