.play-button-wrapper_register {
    display: block;
    margin: auto;
    width: 90px;
}

.play-gif_register {
    display: block;
    position: absolute;
    top: 235px;

}

svg {
    width: 60px;
    height: 60px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    height: 18px
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.register_form {
    width: 40%;
    /* margin-top: 60px; */
    /* margin-bottom: 20px; */
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 30px 20px 0px 20px;
    max-width: 470px;

    /* filter: drop-shadow(0px 0px 2px #D9A22E); */
}

.register_form li {
    display: grid;
    margin-bottom: 10px;
}

.register_main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    display: flex;
    /* background-color: rgba(0, 0, 0, 0.5) !important; */
    /* background-image: url("../../assets/images/bg_img2.png"); */
    background-size: cover;
    /* Added property */
    background-repeat: no-repeat;
}

.register_form {
    min-height: 100px;
    max-height: 580px;
}

.create_account_button {
    width: calc(100% - 30px);
    color: black !important;
    background: #c8bba1 !important;
    margin: 10px 0px 0px 0px !important;
    height: 40px;
    font-weight: bold !important;
    font-size: 20px !important;
    border-radius: 5px;
}

.form_card {
    width: 70%;
}

/* .field_name {
    width: 45%;
}
.MuiBox-root{
    padding: 5px;
} */

.video_main_register {}

.required_field {
    color: red;
}

.input_box {
    height: 25px;
    width: 55%;
}

.field_name {
    padding-top: 6px;
    width: 45%;
}

.register_form {
    /* background-color: rgb(255,255,255); */
    /* border: 2px solid #c8bba1; */
}

.login_button {
    padding-top: 20px;
}

.register_header {
    display: flex;
    justify-content: center;
    font-size: xx-large;
    font-weight: 600;
}

.inputField {
    background: white;
    border-radius: 5px;
    height: 50px;
    width: 100% !important;
    border: none;
}

.inputField .MuiOutlinedInput-input {
    padding: 15px 14px 16px 0px;
}

.form_container {
    min-width: 470px;
}

.registerBottomText {
    color: white;
}

.welcomeText {
    font-weight: 600;
    color: white;
    marginBottom: 40px !important;
    font-size: 25px;
}

@media screen and (max-width:1300px) {
    .video_main_register {
        display: none;
    }


}

@media screen and (max-width:650px) {
    .video_main_register {
        display: none;
    }

    .form_container {
        min-width: 400px;
    }


}

@media screen and (max-width:550px) {
    .video_main_register {
        display: none;
    }

    .form_container {
        min-width: 350px;
    }

    .welcomeText {
        font-weight: 600;
        font-size: 17px;
    }


}

.recaptcha_perent {}

@media screen and (max-width:450px) {
    .video_main_register {
        display: none;
    }

    .recaptcha_perent {
        scale: 0.9;
        margin-right: 200px;
    }

    .form_container {
        min-width: 280px;
    }

    .registerBottomText {
        color: white;
        font-size: 13px !important;
        letter-spacing: 0.00790em !important;

    }



}